import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/index";
import axios from "axios";

const initialState = {
  loading: false,
  createLoading: false,
  contentCreated: false,
  error: false,
  data: [],
  totalPage: 0,
  totalContents: 0,
};

const contentsSlice = createSlice({
  name: "contents",
  initialState,
  reducers: {
    resetContentCreated: (state, action) => {
      state.contentCreated = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllContents.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllContents.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllContents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createNewContent.pending, (state, action) => {
        state.createLoading = true;
      })
      .addCase(createNewContent.fulfilled, (state, action) => {
        state.createLoading = false;
        state.contentCreated = true;
        state.data = [...state.data, action.payload];
      })
      .addCase(createNewContent.rejected, (state, action) => {
        state.createLoading = false;
        state.error = action.payload;
      });
  },
});

export const getAllContents = createAsyncThunk(
  "contentsSlice/getAllContents",
  async (data) => {
    try {
      const { url, method, headers } = api.getAllContents();

      const contents = await axios({
        url,
        method,
        headers,
      });
      return contents.data;
    } catch (error) {
      return error.message;
    }
  }
);
export const createNewContent = createAsyncThunk(
  "contentsSlice/createNewContent",
  async (contentData) => {
    try {
      const { url, method, headers, data } = api.createNewContent(contentData);

      const contents = await axios({
        url,
        method,
        headers,
        data,
      });
      return contents.data;
    } catch (error) {
      return error.message;
    }
  }
);

export const { actions } = contentsSlice;
export const { resetContentCreated } = contentsSlice.actions;
export default contentsSlice.reducer;
